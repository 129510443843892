import serviceWorkerUrl from "worker-plugin/loader?name=sw&esModule!../worker/index.worker.ts";
import { logger } from "./logger.util";

export interface ServiceWorkerPostMessagePayload {
  message: string;
  data: any;
}

export const register = async () => {
  if (!navigator.serviceWorker) {
    logger.error("Failed to register service worker");
    return;
  }

  // HACK: worker-plugin doesn't allow setting a custom publicPath for the worker url
  // (it uses __webpack_public_path__). Since all of the other js files are accessed via CDN url
  // (e.g., cdn.getclockwise.com) and web workers must have the same origin as the page
  // (e.g., www.getclockwise.com), we need to rewrite the origin of the worker url.
  const url = new URL(serviceWorkerUrl, window.location.origin);
  const registration = await navigator.serviceWorker.register(`${url.pathname}${url.search}`);

  registration.addEventListener("updatefound", () => {
    // If updatefound is fired, it means that there's
    // a new service worker being installed.
    console.log("service worker: new service worker is being installed:", registration.installing);
  });
};
