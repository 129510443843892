//////////////
// IMPORTS
//////////////
import { AnyAction, applyMiddleware, compose, createStore, Store } from "redux";
import { IReduxState, rootReducer } from "./reducers/root.reducer";
// the rest are only used in web; so we may be over-importing for chrome... oh well
import { setMode } from "#webapp/src/state/actions/chrome-extension.actions";
import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
import * as Sentry from "@sentry/browser";
import {
  BrowserProtocol,
  createHistoryEnhancer,
  Actions as FarceActions,
  queryMiddleware,
} from "farce";
import createSentryMiddleware from "redux-sentry-middleware";

// Sentry middleware for error reporting
const middlewares =
  getEnvironment() === "production"
    ? [createSentryMiddleware(Sentry as any)] // <-- no idea why this type is complaining
    : [];

// redux dev-tools
const reduxWindow = (window as unknown) as { __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any } & Window;
const composeEnhancers = reduxWindow.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// the modern store requires some more config
export async function createReduxStoreModern(): Promise<Store<IReduxState, AnyAction>> {
  const protocol = new BrowserProtocol();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const composeArgs: any[] = [
    applyMiddleware(...middlewares),
    createHistoryEnhancer({
      protocol,
      middlewares: [queryMiddleware],
    }),
  ];

  const modernReduxStore = createStore<IReduxState, AnyAction, {}, {}>(
    rootReducer,
    composeEnhancers(...composeArgs),
  );

  modernReduxStore.dispatch(FarceActions.init());
  modernReduxStore.dispatch(setMode("webapp"));
  return modernReduxStore;
}
