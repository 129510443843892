//////////////
// IMPORTS
/////////////

// Ensure init-env is imported first to make sure public path and environment
// overrides are set before importing any other constants
import "@clockwise/web-commons/src/config/init-env";
// To avoid import autosort moving the init-env import, add other imports below this comment

// libraries
import * as Sentry from "@sentry/browser";
import * as React from "react";
import { Store } from "redux";
// util
import { isValidJson } from "@clockwise/web-commons/src/util/json.util";
import { ChromeIdleThreshold } from "@clockwise/web-commons/src/util/post-message-common.util";
import { getSession } from "./util/auth.util";
import { getHealthAugmented, loadDowntime, setupDowntimeInterval } from "./util/health.util";
import { windowLocation } from "./util/location.util";
import { logger } from "./util/logger.util";
import { Monitor } from "./util/monitoring.util";
import { createPolyfills } from "./util/polyfill.util";
import { PostMessageManager } from "./util/post-message.util";
// internal
import { getApiUrl } from "@clockwise/client-commons/src/config/api";
import { getEnvironment } from "@clockwise/client-commons/src/config/environment";
import { debounce } from "lodash";
import { RootModern } from "./components/root/RootModern";
import { IReduxState } from "./state/reducers/root.reducer";
import { initReduxStore } from "./state/redux-store";
import { createReduxStoreModern } from "./state/redux-store.modern";
import { createEnvironment } from "./state/relay-environment";

// top-level css imports
import "./assets/css/chrome.css";
import "./assets/css/index.css";

import { createRoot } from "react-dom/client";
import * as serviceWorker from "./util/service-worker.util";

async function main() {
  const healthPayload = await getHealthAugmented();

  setupDowntimeInterval(healthPayload);

  // creates the redux store for Chrome - important to do this right away
  // we also use this as a flag to determine if we are initialized or not

  const modernReduxStore = await createReduxStoreModern();
  initReduxStore(modernReduxStore);

  // first, we need to polyfill
  createPolyfills();

  // init Sentry
  if (getEnvironment() === "production") {
    Sentry.init({
      dsn: "https://f04d9c7a34e745548033e1b04b37bfc0@sentry.io/164680",
      release: process.env.RELEASE,
      environment: getEnvironment(),
    });
  }

  //////////////
  // APP
  /////////////
  // init the post message manager immediately so we can do proper analytics tracking
  PostMessageManager.init(modernReduxStore);

  if (healthPayload.health === "dead") {
    return loadDowntime(healthPayload);
  }

  const monitor = new Monitor("modern");
  monitor.start();

  // fetch the session
  await getSession(modernReduxStore);
  loadApp(modernReduxStore);
}

// at some point this function should be generalized to be shared with chrome-extension
// right now, that seems like a ton of work, for little gain
function updateIfReadyAndIdle() {
  if (fetch) {
    let idle = false;
    let hovered = true;
    let pendingIdleUpdate = false;
    let lastActivity = new Date().valueOf();
    let idleTime: number | undefined = undefined;
    let forceTime: number | undefined = undefined;

    const doIdleCheck = () => {
      const newActivity = new Date().valueOf();
      if (newActivity - lastActivity > ChromeIdleThreshold && !hovered) {
        if (pendingIdleUpdate) {
          void windowLocation.reload("ModernDelayedIdleCheck");
        }

        idle = true;
      }
    };

    const doUpdateAndCheck = () => {
      void fetch(`${getApiUrl()}/update`, { credentials: "include" }).then((response) => {
        void response.text().then((text) => {
          type ExpectedParseValue = {
            chromeAppUpdateTimes: {
              idleTime: number;
              forceTime: number;
            };
          };

          const parsed = (isValidJson(text) && (JSON.parse(text) as ExpectedParseValue)) || null;
          if (parsed?.chromeAppUpdateTimes) {
            const newIdleTime = parsed.chromeAppUpdateTimes.idleTime;
            const newForceTime = parsed.chromeAppUpdateTimes.forceTime;

            // update force
            if (newForceTime && newForceTime !== forceTime) {
              if (forceTime) {
                void windowLocation.reload("ModernForceCheck");
              }

              forceTime = newForceTime;
            }

            // update idle
            if (newIdleTime && newIdleTime !== idleTime) {
              if (idleTime && idle && !hovered) {
                void windowLocation.reload("ModernIdleCheck");
              } else if (idleTime) {
                pendingIdleUpdate = true;
              }

              idleTime = newIdleTime;
            }
          }
        });
      });
    };

    document.addEventListener("mouseenter", () => (hovered = true));
    document.addEventListener("mouseleave", () => (hovered = false));
    window.addEventListener(
      "mousemove",
      debounce(() => (lastActivity = new Date().valueOf()), 5000),
    );

    setInterval(doIdleCheck, 1000);
    setInterval(doUpdateAndCheck, 3600000); // same value as ChromeWrapper.tsx
  } else {
    logger.error("Failed to fetch /update, fetch not found on window");
  }
}

function loadApp(store: Store<IReduxState>) {
  // create the relay modern environment
  createEnvironment(store);

  updateIfReadyAndIdle();

  const rootElem = document.getElementById("root");
  if (!rootElem) {
    throw new Error("Root element not found");
  }
  createRoot(rootElem).render(<RootModern reduxStore={store} />);

  if ("serviceWorker" in navigator && "PushManager" in window) {
    void serviceWorker.register();
  }
}

void main();
